import { useState } from "react";
import { BeatLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useAuthProvider } from "../../providers/auth";
import { defaultDataProvider, useDataProvider } from "../../providers/data";
import "./robot.css";

const api_path = process.env.REACT_APP_VERCEL_URL;

const RobotSubPage = ({ serial }) => {
	const { t } = useTranslation();
	// Proveedor de autenticación
	const authProvider = useAuthProvider();

	// Proveedor de datos
	const dataProvider = useDataProvider();

	const localDataProvider = defaultDataProvider(api_path);

	const userData = authProvider.getIdentity();

	const [emptyForm, setEmptyForm] = useState(true);
	const [robotData, setRobotData] = useState({
		clientId: "",
		clientName: "",
		robotId: "",
		email: "",
		registered: null,
		enabled: null,
		activated: null,
		userId: "",
		firmwareVersion: "NH-20240930.24"
	});

	const {
		isLoading: profileIsLoading,
		isError: profileIsError,
		error: profileError
	} = useQuery(
		["soporte/info", { serial: serial }],
		async () => {
			const response = await dataProvider.getOne(`soporte/info?serial=${serial}`);
			console.log(response.data);
			setRobotData(currentData => {
				return {
					...currentData,
					...response.data
				};
			});
			setEmptyForm(false);
			return response.data;
		},
		{
			enabled: emptyForm
		}
	);

	const [robotSyncIsLoading, setRobotSyncIsLoading] = useState(false);
	const [robotSyncMessage, setRobotSyncMessage] = useState("");

	const [seed, setSeed] = useState("");
	const [robotPinIsLoading, setRobotPinIsLoading] = useState(false);
	const [robotPin, setRobotPin] = useState("");

	const [robotActivateIsLoading, setRobotActivateIsLoading] = useState(false);
	const [robotActivateMessage, setRobotActivateMessage] = useState("");
	const [messageIsError, setMessageIsError] = useState(false);

	if (!serial) {
		return <>{t("robot_serial_empty")}</>;
	}

	return (
		<>
			{robotData.firmwareVersion !== "NH-20240930.24"  && !robotData.betatester && (
				<div className="p-6 max-w-2xl mx-auto bg-white rounded-2xl shadow-lg">
					<h2 className="text-xl font-bold mb-4">¡Actualiza la versión de tu iChef!</h2>
					<p className="mb-4">
					Tu iChef cuenta con una nueva versión que suma nuevas funcionalidades. Sigue el instructivo para su actualización:
					</p>
					<ul className="list-disc list-inside mb-4">
						<li>
							Descarga los archivos de actualización:{" "}
							<a
								href="https://sailbot-users-multimedia.s3.us-east-1.amazonaws.com/file-hosting/picture_v2.bin"
								className="text-blue-600 underline"
								target="_blank"
								rel="noopener noreferrer"
							>
								picture_v2.bin
							</a>{" "}
							y{" "}
							<a
								href="https://sailbot-users-multimedia.s3.us-east-1.amazonaws.com/file-hosting/zImageF60.bin"
								className="text-blue-600 underline"
								target="_blank"
								rel="noopener noreferrer"
							>
								zImageF60.bin
							</a>
						</li>
						<li>Copia ambos archivos a un pendrive (sin colocarlos dentro de ninguna carpeta).</li>
						<li>El pendrive debe estar formateado en FAT32 para poder ser recoocido por tu iChef</li>
						<li>Con el equipo apagado, conecta el pendrive al puerto USB de tu iChef.</li>
						<li>Enciende el equipo, la pantalla del robot mostrará un mensaje similar a este:</li>
					</ul>
					<pre className="bg-gray-100 p-4 rounded-lg overflow-auto">
						usb disk boot start{"\n"}
						watchdog reset....{"\n"}
						find file 0:/zImageF60.bin{"\n"}
						program:100%{"\n"}
						find file 0:/picture_v2.bin{"\n"}
						program:100%{"\n"}
						program complete
					</pre>
					<ul className="list-disc list-inside my-4">
						<li>
							Espera a que finalice el proceso: no interrumpas la actualización hasta que veas el mensaje{" "}
							<code>"program complete"</code>.
						</li>
						<li>Una vez finalizado, desconecta el pendrive y reinicia el equipo.</li>
					</ul>
					<p>
						Para comprobar que el robot se haya actualizado con éxito, ve a la sección <strong>"Configuración"</strong> y
						selecciona la opción <strong>"Actualización del sistema"</strong>, revisa que la versión sea:{" "}
						<code>NH-20240930.24</code>.
					</p>
					<p className="mt-4">
						La versión actual de tu robot es: <strong>{robotData.firmwareVersion}</strong>
					</p>
					<p className="mt-4">
						<strong>Nota:</strong> Si tienes alguna duda o problema con la actualización, no dudes en contactar a iChef Care.
					</p>
				</div>
			)}

			<div className="cyg-row">
				<div className="cyg-col-full">
					{!profileIsLoading &&
						(!profileIsError ? (
							<>
								<div className={`estado ${robotData.registered ? "activo" : ""}`}>
									<section>
										<i></i>
										<span>{robotData.registered ? "REGISTRADO" : "NO REGISTRADO"}</span>
									</section>
									<span>
										{robotData.registered
											? "Este equipo se encuentra registrado en el validador."
											: "Este equipo se no está registrado."}
									</span>
								</div>
								{/*<div className={`estado ${robotData.enabled ? 'activo' : ''}`}>
									<section>
										<i></i>
										<span>{robotData.enabled ? 'HABILITADO' : 'INHABILITADO'}</span>
									</section>
									<span>
										{robotData.enabled
											? 'Este cliente se encuentra habilitado para utilizar nuestro servicio.'
											: 'Este cliente no está habilitado.'}
									</span>
								</div>*/}
								<div className={`estado ${robotData.activated || robotData.free ? "activo" : ""}`}>
									<section>
										<i></i>
										<span>{robotData.activated || robotData.free ? "ACTIVO" : "INACTIVO"}</span>
									</section>
									<span>
										{robotData.activated || robotData.free
											? "Este equipo se encuentra operativo actualmente"
											: "Este equipo no se encuentra operativo"}
									</span>
								</div>
								<div className={`estado ${robotData.free ? "activo" : robotData.unlockable ? "yellow" : ""}`}>
									<section>
										<i></i>
										<span>{robotData.free ? "LIBERADO" : robotData.unlockable ? "LIBERABLE" : "NO LIBERABLE"}</span>
									</section>
									<span>
										{robotData.free
											? "El robot se encuentra liberado"
											: robotData.unlockable
											? robotData.pendingSetup
												? "Necesita actualizar el robot a la version que habilita la liberacion"
												: "El robot puede ser liberado"
											: "No se puede liberar el robot"}
									</span>
								</div>
							</>
						) : (
							<p>{profileError}</p>
						))}
				</div>
			</div>

			{!robotData.free && (
				<div className="cyg-row">
					<div className="cyg-col-full col-form col-form-pin">
						<h6>{t("generate_pin")}</h6>
						<form
							className="form-ichef herramientas"
							onSubmit={event => {
								event.preventDefault();
								setRobotPinIsLoading(true);
								localDataProvider
									.getOne(`api/users/robot/pin/${serial}/${seed}`, {
										headers: {
											"Content-Type": "application/json",
											Accept: "application/json",
											Authorization: userData.jwt
										}
									})
									.then(response => {
										const { data } = response.data;
										setRobotPinIsLoading(false);
										setRobotPin(data ? data.pinRobot : "");
									});
							}}
						>
							<input
								type="text"
								name="seed"
								placeholder={t("code")}
								value={seed}
								onChange={event => {
									setSeed(event.target.value);
									setRobotPin("");
								}}
								required
								autoComplete="off"
							/>
							<input type="text" value={robotPin} readOnly={true} />
							<button type="submit" className="transition">
								{t("generate")}
							</button>
						</form>

						<section className="sec-msj">
							{robotPinIsLoading && (
								<BeatLoader
									color={"#509F2C"}
									loading={true}
									cssOverride={{
										display: "block",
										margin: "0 auto",
										borderColor: "#509F2C"
									}}
									size={10}
								/>
							)}
						</section>
					</div>
				</div>
			)}

			{!robotData.free && robotData.unlockable && !robotData.pendingSetup && (
				<div className="cyg-row">
					<div className="cyg-col-full col-form col-form-activacion">
						<h6>{t("robot_release")}</h6>
						<form
							className="form-ichef herramientas"
							onSubmit={event => {
								event.preventDefault();
								setRobotActivateIsLoading(true);
								dataProvider
									.create(`soporte/liberarRobot?serial=${serial}`)
									.then(response => {
										const { data } = response.data;
										setMessageIsError(false);
										setRobotActivateIsLoading(false);
										setRobotActivateMessage(data ? data.message : "");
									})
									.catch(error => {
										const { data } = error.response;
										setMessageIsError(true);
										setRobotActivateIsLoading(false);
										setRobotActivateMessage(data ? data.message : "");
									});
							}}
						>
							<button type="submit" className="transition">
								{t("release_robot")}
							</button>
						</form>

						<section className="sec-msj">
							{!robotActivateIsLoading ? (
								robotActivateMessage && (
									<div className={`msj ${messageIsError ? "error" : "ok"}`}>{robotActivateMessage}</div>
								)
							) : (
								<BeatLoader
									color={"#509F2C"}
									loading={true}
									cssOverride={{
										display: "block",
										margin: "0 auto",
										borderColor: "#509F2C"
									}}
									size={10}
								/>
							)}
						</section>
					</div>
				</div>
			)}

			<div className="cyg-row">
				<div className="cyg-col-full col-form col-form-actualizar-recetas">
					<h6>{t("update_recipes")}</h6>
					<form
						className="form-ichef herramientas"
						onSubmit={event => {
							event.preventDefault();
							setRobotSyncIsLoading(true);
							localDataProvider
								.getOne(`api/users/robot/sync/${serial}`, {
									headers: {
										"Content-Type": "application/json",
										Accept: "application/json",
										Authorization: userData.jwt
									}
								})
								.then(response => {
									const { data } = response.data;
									setMessageIsError(false);
									setRobotSyncIsLoading(false);
									setRobotSyncMessage(data ? data.message : "");
								});
						}}
					>
						<button type="submit" className="transition">
							{t("update")}
						</button>
					</form>

					<section className="sec-msj">
						{!robotSyncIsLoading ? (
							robotSyncMessage && <div className="msj ok">{robotSyncMessage}</div>
						) : (
							<BeatLoader
								color={"#509F2C"}
								loading={true}
								cssOverride={{
									display: "block",
									margin: "0 auto",
									borderColor: "#509F2C"
								}}
								size={10}
							/>
						)}
					</section>
				</div>
			</div>
		</>
	);
};

export default RobotSubPage;
