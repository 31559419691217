import { useState } from 'react'
import { ReactComponent as IcoUsuario } from '../../images/svg/ico-usuario.svg'
import { useAuthProvider } from '../../providers/auth'
import { useNavigate } from 'react-router-dom'
import { useServiceProvider } from '../../providers/service'
import { useDataProvider } from '../../providers/data'
import { FORMTYPE, STATUS_SERVER } from '../../providers/utils/functions'
import { PRIVILEGIOS, tienePermisoDe } from '../../providers/utils/functions'
import { useTranslation } from "react-i18next";

function LoginForm({ setUserFormType }) {
	const { t } = useTranslation();
	//Proveedor de autenticación
	const authProvider = useAuthProvider()

	const hostname=window.location.hostname

	//Proveedor de datos
	const localApiDataProvider = useDataProvider('localApi')
	const dataProvider = useDataProvider()

	//Proveedor de servicios
	const serviceProvider = useServiceProvider()

	const navigate = useNavigate()

	const [userName, setUserName] = useState('')
	const [password, setPassword] = useState('')
	const [serverMsg, setServerMsg] = useState({ status: null, msg: '', show: false })
	const { EDITAR_CARPETAS_PUBLICAS } = PRIVILEGIOS

	const closeLoginLightBox = () => {
		serviceProvider.LoginLightBoxOff()
		serviceProvider.menuOff()
	}

	async function GetPublicFolders() {
		try {
			const response = await dataProvider.getList('api/carpetas', {
				headers: {
					'Content-Type': 'application/json',
					'Accept': '*/*',
				}
			});
			return response.data;
		} catch (error) {
			return [];
		}
	}

	async function GetUserFolders(jwt) {
		try {
			const response = await dataProvider.getList('api/carpetas', {
				headers: {
					Authorization: jwt,
					'Content-Type': 'application/json',
					'Accept': '*/*',
				}
			});
			return response.data;
		} catch (error) {
			return [];
		}
	}

	async function GetRecipes(idCarpeta, jwt) {
		try {
			const response = await dataProvider.getList(`api/carpetas/${idCarpeta}`, {
				headers: {
					...(jwt && { Authorization: jwt }),
					'Content-Type': 'application/json',
					'Accept': '*/*',
				}, data: {
				}
			});
			return response.data;
		} catch (error) {
			return [];
		}
	}

	const handleLogin = async (e) => {
		e.preventDefault()

		authProvider
			.login(userName, password)
			.then(async (userAuthenticated) => {
				try {
					const { data, status } = await localApiDataProvider.getFavoriteRecipes({
						headers: {
							'Content-Type': 'application/json',
							Accept: 'application/json, text/plain, */*',
							Authorization: userAuthenticated.jwt,
						},
						params: {
							userId: userAuthenticated.idUsuario,
						},
					})

					if (status === 200) {
						authProvider.updateFavorites(data.map((recipe) => recipe.idReceta))
						authProvider.updateLikes(
							data.filter((recipe) => recipe.likes === 1).map((recipe) => Number(recipe.idReceta))
						)
					}

					try {
						// Fetch public and user folders concurrently
						const [publicFolders, userFolders] = await Promise.all([
							GetPublicFolders(),
							GetUserFolders(userAuthenticated.jwt),
						])

						// Fetch recipes for both public and user folders concurrently
						const [publicFolderRecipes, userFolderRecipes] = await Promise.all([
							Promise.all(publicFolders.map(folder => GetRecipes(folder.idCarpeta, null))),
							Promise.all(userFolders.map(folder => GetRecipes(folder.idCarpeta, userAuthenticated.jwt))),
						])

						// Assign the recipes to their respective folders
						publicFolders.forEach((folder, index) => {
							folder.readOnly = !tienePermisoDe(EDITAR_CARPETAS_PUBLICAS, userAuthenticated)
							folder.cssClass = !tienePermisoDe(EDITAR_CARPETAS_PUBLICAS, userAuthenticated)  ? 'fixed': 'public'

							folder.recipes = publicFolderRecipes[index].map(recipe => recipe.idReceta);
						})

						userFolders.forEach((folder, index) => {
							folder.recipes = userFolderRecipes[index].map(recipe => recipe.idReceta);
						})

						// Remove duplicated folders
						const filterUserFolders = userFolders.filter(f1 => !publicFolders.some(f2 => f1.idCarpeta === f2.idCarpeta))

						// Combine and set all folders
						authProvider.updateFolders(publicFolders.concat(filterUserFolders))
					} catch (error) {
						console.error(error)
					}

					closeLoginLightBox()
					setServerMsg({ status: null, msg: '', show: false })
				} catch (e) {
					closeLoginLightBox()
					setServerMsg({ status: null, msg: '', show: false })
				}

				navigate('/')
			}).catch((error) => {
				setServerMsg({ status: STATUS_SERVER.ERROR, msg: error, show: true })
			})
	}

	return (
		<form action="" className="transition form-ichef">
			<header className="text-center">
				<h6>
					<IcoUsuario />
					{t("login")}
				</h6>
			</header>

			<div>
				<input
					type="text"
					name="user"
					placeholder={t("user")}
					onChange={(e) => setUserName(e.target.value)}
					required
				/>
				<input
					type="password"
					name="pass"
					placeholder={t("password")}
					onChange={(e) => setPassword(e.target.value)}
					required
				/>
			</div>

			<button className="transition" name="submit" onClick={(e) => handleLogin(e)}>
				{t("login")}
			</button>
			<footer>
				<p>
				{ (
					<a href="/register">
						{t("create_account")}
					</a>
				)}
				</p>

				{hostname.includes("recipes.ichef.us") ? (
					<p>
					<a
						href="/#"
						onClick={(event) => {
							event.preventDefault()
							setUserFormType(FORMTYPE.ACTIVATE_ACCOUNT)
							setServerMsg({ status: null, msg: '', show: false })
						}}
					>
						{t("activate_account")}
					</a>
				</p>
				) : (
					<></>
				)}

				
				<p>
					<a
						href="/#"
						onClick={(event) => {
							event.preventDefault()
							setUserFormType(FORMTYPE.PASSWORD_RESET_REQUEST)
							setServerMsg({ status: null, msg: '', show: false })
						}}
					>
						{t("recover_password")}
					</a>
				</p>
			</footer>

			{serverMsg && serverMsg.show ? (
				<div
					className={`msj ${
						serverMsg.status === STATUS_SERVER.ERROR
							? 'error'
							: serverMsg.status === STATUS_SERVER.SUCCESS
							? 'ok'
							: ''
					}`}
				>
					{serverMsg.msg}
				</div>
			) : (
				<></>
			)}
		</form>
	)
}

export default LoginForm
