import { useState } from 'react'
import { useTranslation } from "react-i18next";
import { useAuthProvider } from '../../providers/auth'
import { defaultDataProvider } from '../../providers/data'
import { STATUS_SERVER } from '../../providers/utils/functions'

const api_path = process.env.REACT_APP_VERCEL_URL

const Profile = ({ profileForm, setProfileForm }) => {
	const { t } = useTranslation();
	// Proveedor de autenticación
	const authProvider = useAuthProvider()

	// Proveedor de datos
	const dataProvider = defaultDataProvider(api_path)

	const userData = authProvider.getIdentity()

	const [serverProfileMsg, setServerProfileMsg] = useState({ status: null, msg: '', show: false })
	const [serverPasswordMsg, setServerPasswordMsg] = useState({ status: null, msg: '', show: false })

	const handleProfileFormData = (event) => {
		let newData = {}
		newData[event.target.name] = event.target.value
		setProfileForm((currentData) => {
			return { ...currentData, ...newData }
		})
	}

	const handleProfileForm = (event) => {
		event.preventDefault()

		setServerProfileMsg({ status: null, msg: '', show: false })

		dataProvider
			.update('api/users/profile', {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: userData.jwt,
				},
				data: profileForm,
			})
			.then((response) => {
				setServerProfileMsg({
					status: STATUS_SERVER.SUCCESS,
					msg: 'Perfil actualizado con éxito.',
					show: true,
				})
			})
			.catch((error) => {
				setServerProfileMsg({
					status: STATUS_SERVER.ERROR,
					msg:
						error.response && error.response.data && error.response.data.message
							? error.response.data.message
							: error.message,
					show: true,
				})
			})
	}

	const handleReportForm = () => {
		window.open('https://forms.gle/xEhcjT1yEN2iBfY37', '_blank')
	}

	const [passwordForm, setPasswordForm] = useState({
		idUsuario: profileForm.idUsuario,
		pwd: '',
		newPwd: '',
	})

	const handlePasswordFormData = (event) => {
		let newData = {}
		newData[event.target.name] = event.target.value
		setPasswordForm((currentData) => {
			return { ...currentData, ...newData }
		})
	}

	const handlePasswordForm = (event) => {
		event.preventDefault()

		setServerPasswordMsg({ status: null, msg: '', show: false })

		dataProvider
			.update('api/users/profile/change-password', {
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: userData.jwt,
				},
				data: passwordForm,
			})
			.then((response) => {
				setServerPasswordMsg({
					status: STATUS_SERVER.SUCCESS,
					msg: 'Contraseña actualizada con éxito.',
					show: true,
				})
			})
			.catch((error) => {
				setServerPasswordMsg({
					status: STATUS_SERVER.ERROR,
					msg:
						error.response && error.response.data && error.response.data.message
							? error.response.data.message
							: error.message,
					show: true,
				})
			})
			.finally(() => {
				setPasswordForm({
					...passwordForm,
					...{idUsuario:profileForm.idUsuario, pwd: '', newPwd: '' },

				})
			})
	}

	return (
		<div className="cyg-row">
			<div className="cyg-col-full">
				<form action="" className="form-ichef herramientas" autoComplete="off" onSubmit={handleProfileForm}>
					<label className="usuario-herr">
						{t("user")} <span>{profileForm.usernameText}</span>
					</label>
					<label className="usuario-herr">
						{t("id_card")} <span>{profileForm.clientId}</span>
					</label>
					<input
						type="text"
						name="username"
						placeholder={t("user")}
						value={profileForm.username}
						onChange={handleProfileFormData}
						// required
						autoComplete="off"
					/>
					<input
						type="text"
						name="name"
						placeholder={t("name")}
						value={profileForm.name}
						onChange={handleProfileFormData}
						// required
						autoComplete="off"
					/>
					<input
						type="text"
						name="phoneNumber"
						placeholder={t("phone")}
						value={profileForm.phoneNumber}
						onChange={handleProfileFormData}
						// required
						autoComplete="off"
					/>
					<input
						type="text"
						name="email"
						placeholder={t("email")}
						value={profileForm.email}
						onChange={handleProfileFormData}
						// required
						autoComplete="off"
					/>
					<button className="transition" name="">
						{t("save")}
					</button>

					{serverProfileMsg && serverProfileMsg.show ? (
						<div
							className={`msj ${
								serverProfileMsg.status === STATUS_SERVER.ERROR
									? 'error'
									: serverProfileMsg.status === STATUS_SERVER.SUCCESS
									? 'ok'
									: ''
							}`}
						>
							{serverProfileMsg.msg}
						</div>
					) : (
						<></>
					)}
				</form>

				<form action="" className="form-ichef herramientas" autoComplete="off" onSubmit={handlePasswordForm}>
					<label className="usuario-herr">{t("change_password")}</label>
					<input
						type="password"
						name="pwd"
						placeholder={t("current_password")}
						value={passwordForm.pwd}
						onChange={handlePasswordFormData}
						// required
						autoComplete="off"
					/>
					<input
						type="password"
						name="newPwd"
						placeholder={t("new_password")}
						value={passwordForm.newPwd}
						onChange={handlePasswordFormData}
						// required
						autoComplete="off"
					/>
					<button className="transition" name="">
						{t("change_password")}
					</button>

					{serverPasswordMsg && serverPasswordMsg.show ? (
						<div
							className={`msj ${
								serverPasswordMsg.status === STATUS_SERVER.ERROR
									? 'error'
									: serverPasswordMsg.status === STATUS_SERVER.SUCCESS
									? 'ok'
									: ''
							}`}
						>
							{serverPasswordMsg.msg}
						</div>
					) : (
						<></>
					)}
				</form>

				<form action="" className="form-ichef herramientas" autoComplete="off" onSubmit={handleReportForm}>
					<label className="usuario-herr">{t("report_page_issue")}</label>
					<button className="transition" name="">
						{t("report_issue")}
					</button>
				</form>
			</div>
		</div>
	)
}

export default Profile
