import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation,useNavigate } from "react-router-dom";
import { useDataProvider } from "../providers/data";
import { STATUS_SERVER } from "../providers/utils/functions";
import Dialog from '../components/Dialog'
import { useMask } from '@react-input/mask';

function RegisterPageUS() {
	const { t } = useTranslation();
	const search = useLocation().search;
	const searchParams = new URLSearchParams(search);
	const [showDialog, setShowDialog] = useState(false)
	const navigate = useNavigate()
	const hostname=window.location.hostname

	const inputPhoneRef = useMask({
		mask: '+1 (___) ___-____',
		replacement: { _: /\d/ },
		showMask:true
	  });

	const [signUpForm, setSignUpForm] = useState({
		serial: searchParams.get("serial"),
		email: "",
		name: "",
		phone: "",
		username: "",
		password: "",
		confirm_pswd: ""
	});

	const dataProvider = useDataProvider();
	const [serverMsg, setServerMsg] = useState({ status: null, msg: "", show: false });

	// eslint-disable-next-line no-unused-vars
	const validateForm = async e => {
		e.preventDefault();
		setServerMsg({ status: null, msg: "", show: false });
		try {
			const { serial, email } = signUpForm;
			const response = await dataProvider.create("usuarios/validate_client_us", {
				data: {
					email: email.trim(),
					serialRobot: serial.trim()
				}
			});
			if (response.status === 200) {
				const name = response.data.nombre;
				const phone = response.data.cellphone;
				setSignUpForm({ ...signUpForm, ...{ name, phone } });
			}
		} catch (error) {
			const msg = error.response.data.message;
			setServerMsg({ status: STATUS_SERVER.ERROR, msg, show: true });
		}
	};

	const submitForm = async e => {
		e.preventDefault();
		setServerMsg({ status: null, msg: "", show: false });

		if (signUpForm.password !== signUpForm.confirm_pswd) {
			setServerMsg({ status: STATUS_SERVER.ERROR, msg: t("passwords_do_not_match"), show: true });
		}

		try {
			const { serial, email, name, phone, username, password } = signUpForm;
			const response = await dataProvider.create("usuarios/register_client_web", {
				data: {
					email: email.trim(),
					serialRobot: serial.trim(),
					nombre: name,
					cellphone: phone,
					usuario: username.toLowerCase().replace(/[^a-z0-9_@]/g, ""),
					pwd: password.trim()
				}
			});
			if (response.status === 200) {
				const user = response.data.usuario;
				const msg = t("user_registered_successfully", { user });
				setServerMsg({ status: STATUS_SERVER.SUCCESS, msg, show: true });
				setShowDialog(true);

			}
		} catch (error) {
			const msg = error.response.data.message;
			setServerMsg({ status: STATUS_SERVER.ERROR, msg, show: true });
			setShowDialog(true);

		}
	};

	return (
		
		<form action="" className="transition form-register" onSubmit={e => submitForm(e)} autoComplete="off">
			{showDialog && (
			<Dialog
				buttons={
					<>
						<button className="btn btn-primary btn-enviar" onClick={() => serverMsg.status === STATUS_SERVER.ERROR ? setShowDialog(false):navigate('/')}>
							{t("confirm")}
						</button>
					</>
				}
			>
			
								<div
						className={`msj-dlg ${
							serverMsg.status === STATUS_SERVER.ERROR ? "error" : serverMsg.status === STATUS_SERVER.SUCCESS ? "ok" : ""
						}`}
					>
						{serverMsg.msg}
					</div><div></div>
			</Dialog>
					)}
			<div className="register-container">
				<header className="text-center">
					<h6>{t("activate_your_ichef_portal_account")}</h6>
				</header>

				{/*serverMsg && serverMsg.show && (
					<div
						className={`msj ${
							serverMsg.status === STATUS_SERVER.ERROR ? "error" : serverMsg.status === STATUS_SERVER.SUCCESS ? "ok" : ""
						}`}
					>
						{serverMsg.msg}
					</div>
					)*/}

					<div>
					<label htmlFor="serial">{t("robot_id")}:</label>
					<input
						type="text"
						name="serial"
						placeholder={t("robot_id")}
						onChange={e => setSignUpForm({ ...signUpForm, ...{ serial: e.target.value } })}
						value={signUpForm.serial}
						required
					/>
					</div>

				{/*<div>
					<label htmlFor="serial">{t("robot_id")}:</label>
					<input
						type="text"
						name="serial"
						placeholder={t("robot_id")}
						onChange={e => setSignUpForm({ ...signUpForm, ...{ serial: e.target.value } })}
						value={signUpForm.serial}
						required
					/>

					<label htmlFor="email">E-mail:</label>
					<input
						type="email"
						name="email"
						placeholder="E-mail"
						onChange={e => setSignUpForm({ ...signUpForm, ...{ email: e.target.value } })}
						value={signUpForm.email}
						required
					/>
				</div>
				<button className="transition" type="button" onClick={e => validateForm(e)}>
					{t("validate")}
				</button>*/}
				<div>
					<label htmlFor="name">{t("name")}:</label>
					<input
						type="text"
						name="name"
						placeholder={t("name")}
						onChange={e => setSignUpForm({ ...signUpForm, ...{ name: e.target.value } })}
						value={signUpForm.name}
					/>

					<label htmlFor="phone">{t("phone_number")}:</label>
					<input
					ref={	hostname.includes("recipes.ichef.us") ?
					inputPhoneRef:undefined}
						type="tel"
						name="phone"
						placeholder={t("phone_number")}
						onChange={e => setSignUpForm({ ...signUpForm, ...{ phone: e.target.value } })}
						value={signUpForm.phone}
					/>
					<label htmlFor="email">E-mail:</label>
					<input
						type="email"
						name="email"
						placeholder="E-mail"
						onChange={e => setSignUpForm({ ...signUpForm, ...{ email: e.target.value } })}
						value={signUpForm.email}
						required
					/>
					<label htmlFor="user">{t("username")}:</label>
					<input
						type="text"
						name="user"
						placeholder={t("username")}
						onChange={e => setSignUpForm({ ...signUpForm, ...{ username: e.target.value } })}
						value={signUpForm.username}
						required
					/>

					<label htmlFor="password">{t("password")}:</label>
					<input
						type="password"
						name="password"
						placeholder={t("password")}
						onChange={e => setSignUpForm({ ...signUpForm, ...{ password: e.target.value } })}
						value={signUpForm.password}
						required
					/>

					<label htmlFor="confirm_pswd">{t("confirm_password")}:</label>
					<input
						type="password"
						name="confirm_pswd"
						placeholder={t("confirm_password")}
						onChange={e => setSignUpForm({ ...signUpForm, ...{ confirm_pswd: e.target.value } })}
						value={signUpForm.confirm_pswd}
						required
					/>
				</div>
				<button className="transition" type="submit">
					{t("register")}
				</button>
			</div>

			
		</form>
		
	);
}

export default RegisterPageUS;
